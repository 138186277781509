import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Hudson({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={{
        backgroundColor: styles.forElement("background").backgroundColor
      }}
    >
      <div
        style={styles.forElement("background")}
        className="formkit-background"
      />
      <div className="formkit-container">
        <div
          className="formkit-card"
          style={styles.forElement("content", {
            backgroundColor: "#ffffff",
            backgroundImage: "",
            borderRadius: 8
          })}
        >
          <Elements.Image
            className="formkit-avatar"
            name="image"
            size={{ h: 100, w: 100 }}
            aspectRatio={1}
            shape="circle"
            defaults={{
              src:
                "https://083950260099-attachments.s3.us-east-2.amazonaws.com/70519/4c338701-5274-40b5-8855-133d75a20896/hudson.jpeg"
            }}
          />
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "Lauren Hudson" }}
          />
          <Elements.Region name="subheader" className="formkit-subheader">
            <Elements.Content
              defaults={{
                content: "Artist, designer, creator."
              }}
            />
          </Elements.Region>
          <Elements.Form
            style={styles.forElement("form", { backgroundColor: "#" })}
          >
            <Elements.Region className="formkit-title" name="title">
              <Elements.Content
                defaults={{
                  content:
                    "Subscribe to Lauren's weekly newsletter on design, marketing, and creativity to learn more about earning a living as a creator."
                }}
              />
            </Elements.Region>
            <Elements.Errors />
            <Elements.CustomFields data-stacked={stackedDisplay}>
              {stackedDisplay && <Elements.AddFieldButton />}
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Subscribe" }}
              />
            </Elements.CustomFields>

            {!stackedDisplay && <Elements.AddFieldButton />}
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </Elements.Form>
        </div>
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

Hudson.style = "landing_page"
Hudson.categories = ["Profile"]
Hudson.thumbnail = ""
Hudson.preview = "https://demo.ck.page/hudson"
Hudson.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Hudson, { name: "Hudson" })
